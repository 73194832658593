import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { createPinia } from "pinia";
//图片预览引入
import vue3PreviewImage from 'vue3-preview-image'
// 处理中文
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// import * as echarts from 'echarts'
//视频组件引入
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css

// 创建pinia实例
const pinia = createPinia()
const app = createApp(App)
// app.config.globalProperties.$tips = tips

// app.config.globalProperties.$NO_AUTHORITY = "/noAuthority"

//请求文件的接口

// app.config.globalProperties.$OSS_PATH = "https://test.ye-san.com/"
// app.config.globalProperties.$WEBSOCKET = "ws://localhost:9090/websocket/"
// app.config.globalProperties.$THREE_COLUMN_PATH = "http://localhost:9192/#/column?id="

app.config.globalProperties.$OSS_PATH ="https://oss.ye-san.com/"
app.config.globalProperties.$WEBSOCKET = "wss://www.ye-san.com/websocket/"
app.config.globalProperties.$THREE_COLUMN_PATH = "https://www.ye-san.com/three/#/column?id="


// app.config.globalProperties.$echarts = echarts

app.use(router)
//注册pinia存储数据的仓库
app.use(pinia)
//视频组件注册
app.use(vue3videoPlay)
//图片预览全局注册
app.use(vue3PreviewImage)
//ElementPlus处理中文组件
app.use(ElementPlus, {
  locale: zhCn,
})

//自动引入ElementPlus组件
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.mount('#app') 